<template>
  <!-- Base Dropdown -->
  <div class="base-dropdown">
    <!-- Dropdown Label -->
    <label class="base-dropdown--label">
      {{ label }}

      <!-- Required Star -->
      <span v-if="required">*</span>
    </label>

    <!-- Dropdown Input -->
    <div
      v-if="!disabled"
      style="position: relative"
    >
      <div
        class="base-dropdown--wrapper"
        :class="{ getInputWrapperClasses, 'ab-border-blue': showDropdown, 'ab-border-grey': !showDropdown }"
        @click="showDropdown = true"
      >
        <input
          v-if="!loading"
          v-model="search"
          :placeholder="placeholder"
        >
        <BaseLoadingSpinner v-else />
      </div>

      <div class="base-dropdown--actions">
        <FontAwesomeIcon
          v-if="clearable && search"
          :icon="['fas', 'times']"
          @click="selectItem({})"
        />

        <FontAwesomeIcon :icon="['fas', 'caret-down']" />
      </div>
    </div>

    <!-- Error -->
    <div
      v-if="error"
      class="base-dropdown--error-message"
    >
      Field is required
    </div>

    <!-- Dropdown Dropdown -->
    <div
      v-if="showDropdown && !loading"
      v-on-dismiss="{ watch: showDropdown, callback: closeDropdown }"
      class="base-dropdown--dropdown"
    >
      <!-- Not Found Message -->
      <div v-if="!getFilteredItems.length">
        <slot
          v-if="$slots['no-data']"
          name="no-data"
        />

        <div
          v-else
          class="base-dropdown--dropdown--item"
        >
          No data found
        </div>
      </div>

      <!-- Items List -->
      <div v-else>
        <div
          v-for="(item, index) in getFilteredItems"
          :key="index"
          class="base-dropdown--dropdown--item"
          @click="selectItem(item)"
        >
          <!-- Item Slot -->
          <slot
            v-if="$scopedSlots['item']"
            name="item"
            :item="item"
          />

          <!-- Item Default -->
          <div v-else>
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueDismiss from '../../mixins/vueDismiss'
import BaseLoadingSpinner from '@/components/globals/BaseLoadingSpinner'

export default {
  name: 'BaseDropdown',
  components: { BaseLoadingSpinner },
  mixins: [vueDismiss],
  props: {
    // Model Value
    value: {
      type: [Object, String],
      default: ''
    },
    // Dropdown Label
    label: {
      type: String,
      default: ''
    },
    // Placeholder
    placeholder: {
      type: String,
      default: 'Select'
    },
    // Is Field Required
    required: {
      type: Boolean,
      required: false
    },
    // Dropdown Items
    items: {
      type: Array,
      default: () => []
    },
    error: {
      type: Boolean
    },
    // Clearable
    clearable: {
      type: Boolean
    },
    // Disabled
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    }
  },
  data () {
    return {
      showDropdown: false,
      search: ''
    }
  },
  computed: {
    // Get Filtered Items
    getFilteredItems () {
      if (this.items.find(i => i.title === this.search)) return this.items.filter(i => i.title !== this.search)
      const filteredItems = this.items.filter(item => item.title.toLowerCase().includes(this.search.toLowerCase()))

      return !filteredItems.length ? this.items : filteredItems
    },
    // Get Input Wrapper Classes
    getInputWrapperClasses () {
      const classes = []

      // Add error class
      this.error ? classes.push('ab-border-red') : classes.push('ab-border-grey')
      this.disabled ? classes.push('base-dropdown--disabled') : classes.push('')

      return classes
    }
  },
  watch: {
    value (newValue) {
      if (typeof newValue === 'object' && !newValue.title) {
        this.search = ''
      }
    }
  },
  mounted () {
    if (this.value.title) {
      this.search = this.value.title
    }
  },
  methods: {
    // Select Item
    selectItem (item) {
      this.search = item.title ? item.title : ''
      this.$emit('input', item)
      this.closeDropdown()
    },
    // Close Dropdown
    closeDropdown () {
      this.showDropdown = false
    }
  }
}
</script>

<style scoped lang="sass">
.base-dropdown
  min-width: 240px
  position: relative

  &--label
    font-size: 15px

    span
      color: red

  &--wrapper
    align-items: center
    border: 1px solid #E6E6E6
    cursor: pointer
    display: flex
    height: 45px
    width: 100%

    input
      background: transparent
      border: none
      cursor: pointer
      line-height: 46px
      font-size: 18px
      outline: none
      overflow: hidden
      padding-left: 15px !important
      text-overflow: ellipsis
      white-space: nowrap
      color: #414042

  &--actions
    color: #696969
    font-size: 18px
    margin-top: 12px
    margin-right: 15px
    position: absolute
    right: 0
    top: 0

  &--dropdown
    animation: open 200ms ease-in
    background: #fefefe
    border: 1px solid #E6E6E6
    margin-top: 5px
    max-height: 300px
    min-height: 20px
    overflow-y: auto
    position: absolute
    width: 100%
    z-index: 500

    &--item
      cursor: pointer
      font-size: 15px
      padding: 10px 10px
      color: #414042

      &:first-child
        padding-top: 10px

      &:last-child
        padding-bottom: 10px

      &:hover
        background: rgba(0, 0, 0, 0.1)

  &--disabled
    background-color: grey

  &--error-message
    color: red
    font-size: 12px
    margin-top: 5px
    position: absolute

@keyframes open
  0%
    transform: translateY(-10px)
    opacity: 0

  100%
    transform: translateY(0)
    opacity: 1
</style>
