<template>
  <!-- Product Item Card -->
  <div class="product-item-card">
    <!-- Product Image -->
    <div class="product-item-card--image">
      <img
        v-if="product.image"
        :src="product.image"
      >

      <div v-else>
        <i class="fas fa-image" />
      </div>
    </div>

    <!-- Content -->
    <div class="product-item-card--content">
      <!-- Product Name -->
      <div
        class="product-item-card--content--name"
        :title="getProductName"
      >
        {{ getProductName }}
      </div>

      <!-- View Product -->
      <div
        class="product-selector-button"
        @click="goToLink"
      >
        View Product
      </div>
    </div>

    <BaseModal
      v-if="showLinkAlert"
      @close="showLinkAlert = false"
    >
      <template #toolbar>
        Redirect Alert
      </template>

      <div style="text-align: center; margin: 0 auto">
        <div style="margin: 30px">
          <div>
            You are being redirected to TetherTools.com where you can get more information about this product.
          </div>
          Ask your local store to stock this item.
        </div>

        <div
          class="product-selector-button"
          style="margin: 0 auto"
          @click="redirectToLink"
        >
          Redirect
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/globals/BaseModal'

export default {
  name: 'ProductItemCard',
  components: { BaseModal },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      showLinkAlert: false
    }
  },
  computed: {
    getProductName () {
      return this.product.dealer_product_name || this.product.name
    }
  },
  methods: {
    // Go To Link
    goToLink () {
      if (!this.product.dealer_permalink) {
        this.showLinkAlert = true
      } else {
        window.open(this.product.dealer_permalink, '_blank')
      }
    },
    // Redirect to Link
    redirectToLink () {
      window.open(this.product.permalink || 'https://tethertools.com', '_blank')
    }
  }
}
</script>

<style scoped lang="sass">
.product-item-card
  border: 1px solid #E6E6E6
  font-family: Roboto, sans-serif
  height: 414px
  max-width: 330px

  &--image
    align-items: center
    display: flex
    height: 184px
    justify-content: center

    img
      height: 100%
      width: 100%
      object-fit: cover

    i
      font-size: 40px
      color: #E6E6E6

  &--content
    border-top: 1px solid #E6E6E6
    display: flex
    flex-direction: column
    height: 160px
    justify-content: space-between
    padding: 32px

    &--name
      font-size: 22px
      font-weight: 400
      overflow: hidden
      text-overflow: ellipsis
      display: -webkit-box
      -webkit-line-clamp: 3
      -webkit-box-orient: vertical

    &--price
      font-size: 26px
      font-weight: 500
</style>
