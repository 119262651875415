<template>
  <!-- Product Selector -->
  <div id="app">
    <BaseHeader
      :title="'Search By Camera'"
    />

    <!-- Dropdown List -->
    <div class="product-selector-dropdown-list">
      <!-- Manufacturer -->
      <BaseDropdown
        v-model="selectedManufacturer"
        :items="manufacturers"
        :loading="loadingManufacturers"
        placeholder="Manufacturer"
        class="product-selector-dropdown-list--item"
      >
        <template #item="{ item }">
          {{ item.title }}
        </template>
      </BaseDropdown>

      <!-- Model -->
      <BaseDropdown
        v-model="selectedModel"
        :items="models"
        placeholder="Model"
        :loading="loadingModels || loadingManufacturers"
        class="product-selector-dropdown-list--item"
      >
        <template #item="{ item }">
          {{ item.title }}
        </template>
      </BaseDropdown>

      <!-- Computer Port -->
      <BaseDropdown
        v-model="selectedPort"
        :items="ports"
        placeholder="Computer Port"
        class="product-selector-dropdown-list--item"
      >
        <template #item="{ item }">
          {{ item.title }}
        </template>
      </BaseDropdown>

      <!-- Get Results -->
      <button
        :disabled="!selectedModel.title || !selectedPort.title"
        class="product-selector-button search"
        @click="fetchResults"
      >
        Get Results
      </button>
    </div>

    <!-- Loading State -->
    <BaseLoadingSpinner v-if="loadingResults" />

    <!-- Category List -->
    <div v-else-if="getCategories.length">
      <ProductCategory
        v-for="(category, index) in getCategories"
        :key="index"
        :category="category"
      />
    </div>

    <!-- No Products Found Message -->
    <div
      v-else-if="hasSearched"
      class="no-products-found-message"
    >
      No products found
    </div>

    <!-- Suggested Accessories  -->
    <div v-if="getAccessories.length && !loadingResults">
      <ProductCategory
        v-for="(category, index) in getAccessories"
        :key="index"
        :category="category"
      />
    </div>
  </div>
</template>

<script>
import ProductCategory from '@/components/product-selector/ProductCategory'
import BaseDropdown from '@/components/globals/BaseDropdown'
import { getCablesResults, getManufacturers, getModels } from '@/api/ProductSelectorServices'
import BaseLoadingSpinner from '@/components/globals/BaseLoadingSpinner'
import BaseHeader from '@/components/globals/BaseHeader'

export default {
  name: 'App',
  components: { BaseLoadingSpinner, BaseDropdown, ProductCategory, BaseHeader },
  props: {
    companyDomain: {
      type: String,
      default: ''
    },
    jerkstopper: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      hasSearched: false,
      loadingManufacturers: false,
      loadingResults: false,
      loadingModels: false,
      // Manufactures
      manufacturers: [],
      selectedManufacturer: {},
      // Models
      models: [],
      // Ports
      ports: [
        { id: 1, title: 'USB-A', value: 'usb-a' },
        { id: 2, title: 'USB-C', value: 'usb-c' }
      ],
      selectedPort: {},
      selectedModel: {},
      categories: [],
      accessories: []
    }
  },
  computed: {
    getCategories () {
      return this.categories.filter(c => c.products.length)
    },
    getAccessories () {
      return this.accessories.filter(c => c.products.length)
    }
  },
  watch: {
    selectedManufacturer: {
      handler (newValue) {
        if (newValue) {
          this.fetchModels()
          this.selectedModel = {}
        }
      },
      deep: true
    }
  },
  async mounted () {
    this.fetchManufacturers()
  },
  methods: {
    // Fetch Manufacturers
    async fetchManufacturers () {
      this.loadingManufacturers = true

      try {
        const manufacturers = await getManufacturers()

        this.manufacturers = manufacturers.sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map(m => {
          return {
            title: m
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingManufacturers = false
      }
    },
    // Fetch Models
    async fetchModels () {
      this.loadingModels = true

      try {
        const models = await getModels(this.selectedManufacturer.title)

        this.models = Object.values(models).sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1).map(m => {
          return {
            title: m
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingModels = false
      }
    },
    // Fetch Results
    async fetchResults () {
      this.hasSearched = true
      this.loadingResults = true

      try {
        const categories = []
        const accessories = []
        const response = await getCablesResults(this.selectedManufacturer.title, this.selectedModel.title, this.selectedPort.value, this.companyDomain, this.jerkstopper !== false, 5000, 1)
        const products = response?.products
        const suggestedAccessories = response?.accessories

        // Grab the active products only
        const activeProducts = products.filter(p => p.status === 'active')

        for (let i = 0; i < activeProducts.length; i++) {
          const currentProduct = activeProducts[i]

          const foundCategory = categories.find(c => c.title.toLowerCase() === currentProduct.type.toLowerCase())

          if (foundCategory) {
            foundCategory.products.push(currentProduct)
          } else {
            if (currentProduct.type === 'software') continue
            categories.push({
              title: currentProduct.type.charAt(0).toUpperCase() + currentProduct.type.slice(1),
              products: [currentProduct]
            })
          }
        }

        if (suggestedAccessories) {
          accessories.push({
            title: 'Suggested Accessories',
            products: suggestedAccessories,
            customClass: 'pc-max-width'
          })
        }

        this.categories = categories
        this.accessories = accessories
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingResults = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
#app
  font-family: Roboto, sans-serif
  width: 100%
  padding-bottom: 40px
  color: #414042 !important

.product-selector-dropdown-list
  align-items: center
  display: flex
  flex-wrap: wrap
  margin-bottom: 0
  padding: 0 15px

  &--question
    color: grey
    cursor: pointer
    font-size: 30px
    margin-right: 30px

  &--item
    margin-right: 30px
    margin-bottom: 10px

.product-selector-button.search
  margin-bottom: 10px
  height: 45px

.product-selector-button:disabled
  background: #dddddd

.no-products-found-message
  font-size: 25px
  padding: 0 15px
  font-weight: 500
  letter-spacing: 3px
  margin: 100px auto 50px
  text-align: left
  width: auto

@media screen and (max-width: 739px)
  .no-products-found-message
    text-align: center

  .product-selector-dropdown-list
    flex-direction: column

    &--item
      margin-right: 0
      width: 240px
</style>
